export const blogPosts = [
  {
    id: 3,
    title: "11/07/23 - OpenAI inside [Insert AEC Design Software Here]",
    content: 
    `<div>
    <p>
    Last weekend, our team, Clippy-AI, competed in a hackathon put on by CORE Studio. 
    Our team coalesced around the idea of integrating OpenAI prompting inside Autodesk Revit, 
    a 3-D building information modeling software used by most players in the AEC industry.  
    </p>
    <p>
    During this process, we experimented with how to write better prompts to get the LLM 
    to do and not to do what we wanted while asking it to write Python scripts that would 
    execute locally on a user's computer.  Initially, we made one API call to ChatGPT 4.0 
    based on a user's text input. However, we found that we struggled to combine the two 
    steps of ensuring the user provided enough information to generate code and generating 
    that code. So we broke it out into a pre-prompt API call and a prompt API call to verify 
    that the user input enough information, i.e., if a user wanted to move a column to the 
    east, but didn't provide a distance, we wanted to reject that prompt and ask the user 
    to input more information. 
    </p>
    <p>
    One tweak we made after getting some issues with code that returned exceptions was to 
    send back those exception messages received when running these Python scripts in hopes 
    that we could get the script corrected. We put a cap on this to limit the maximum number 
    of requests to be set by the user, but we found that any more than five didn't help.
    </p>
    <p>
    Another early discovery that greatly simplified our initial planning was that we 
    discovered that chatGPT natively knew Autodesk Revit API, so we were able to get 
    decent results without feeding it the API data.  However, knowledge of the API was a 
    little weak in some areas, so future work would require sending that API information as context to improve performance. 
    </p>
    <p>
    Overall, although the code generation was not perfect, it was nonetheless an exciting 
    discovery of how traditional AEC software is moving towards a future where prompting 
    could be part of the overall design process. 
    </p>
    <p>
    Check out the GitHub: 
    <a href="https://github.com/rfeathers068/clippy-ai">Clippy-AI</a>
    </p>
    </div>
    `
  },
    {
      id: 2,
      title: "07/24/23 - Building A Personal Website",
      content: 
      `<div>
      <p><span>This website highlights my public and private GitHub projects on the <a href="https://www.feathers-codes.com/projects">
      Projects</a> page and my unique background on the <a href="https://www.feathers-codes.com/about">About</a> page. It will also 
      highlight future coding challenges and resolutions here on this blog page.</span></p> 

      <p><span>I started this project after battling deserialization/ serialization issues when setting up an API endpoint for AutoSTL 
      caused by internal library issues and .NET/ .Net Framework compatibility issues. I found several beautiful blogs along the 
      way that helped to guide me toward finding a solution by explaining the problem and allowing me to devise a solution that 
      worked for my unique situation. Even though I could blog internally, post-resolution, it would be nice to have longer-lasting 
      documentation of different engineering challenges like this.</span></p> 
      
      <p><span>This website was an excellent way to jump back into react.js after finishing school and having some time to 
      build more creatively.</span></p> 
      To Future Adventures,
      <br></br>
      Russell
      <br></br>
    </div>`
    },
    {
      id: 1,
      title: "07/21/23 - Intro to Python (2019/2020)",
      content: 
      `<div>
        <p><span>The first Python program I wrote was a game called Rice Rocks, which I wrote as part of a Coursera course 
        through Rice University. Building Rice Rocks wasn't particularly challenging, but it was engaging and provided a 
        feedback loop that made it quite addictive and easy to get absorbed late into the night after work and on weekends. 
        After building Rice Rocks, I took the rest of the series (7 courses) covering data structures, algorithms, etc.  Link to the code
        is here: 
        <a href="https://www.google.com/url?q=https://py2.codeskulptor.org/%23user46_Bag4I604RS_0.py&amp;sa=D&amp;source=editors&amp;ust=1689976954026656&amp;usg=AOvVaw2LP_Pmsn0U4Kbf6KGtLT_j" target="_blank" rel="noreferrer">
            Rice Rocks on Code Skulptor
        </a></span></p>
        <figure style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
        <img src="/blog/image2.png" style="max-width: 400px;" alt="image2">
        </figure>
        <p><span>After I finished the courses, I was also working on some challenging structural engineering projects -- 
        an undulating concrete roof for a pop star in LA and a museum on the Princeton University campus. On the Princeton project, 
        I was the PM of the project's steel design and directly managed a junior engineer to calculate the structure's movement along 
        the building's perimeter. I instructed the junior engineer to put together the calculations but was surprised this was taking 
        almost two days, and we were less than halfway through. She demonstrated her workflow to me, and I couldn't believe how painful 
        the process of clicking through each menu to get the information needed to perform this task ended up being. 
        So, after gathering my thoughts, we sent out the current set of work, and I worked on devising a longer-term plan. 
        I discussed this with my peers and found the engineering API could automate this calculation with some clever manipulation. 
        I wrote some Python scripts that took a multi-day manual process and converted it to a semi-assisted 10-minute process 
        (the non-linear analysis of the structure slowed the actual run-time) through code that was 100-200 lines.</span></p>
      </div>`
    }
  ];
  