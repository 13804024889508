import './Home.css';
import React from 'react';
import AboutMeContent from './content/AboutMeContent.jsx';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className = "home-flex">
      <div className="home">
        <h1 style={{marginBottom: '5px', paddingBottom: '0px'}}>Hi, I'm Russell</h1>
        <h3 style={{marginTop: '0px', marginBottom: '5px'}}>A Full-Stack Developer With 3+ Yrs Exp.</h3>
        <Link className="portfolio-link" to="/projects" style={{marginTop: '5px'}}>Check Out My Portfolio</Link>
        <div className = "profile">
            <img src="/profile_feathers_live_cropped.png" alt="Profile" className="profile-pic"/>
        </div>
        <div className="social-links">
          <a className="links" href="https://www.linkedin.com/in/russell-feathers/" target="_blank" rel="noopener noreferrer">
            <img className="links" src="/linkedin_icon.png" alt="LinkedIn" />
          </a>
          <a href="https://github.com/birdman093" target="_blank" rel="noopener noreferrer">
            <img className="links github-icon-home" src={getComputedStyle(document.querySelector(':root')).getPropertyValue('--github-icon')} alt="GitHub" />
          </a>
          <a href="mailto:russfeathers@gmail.com"><img className = "links" src = "/email.png" alt = "email"></img
          ></a>
        </div>
      </div>
      <div className ="about" style={{marginTop: '0px', paddingTop: '0px'}}>
        <AboutMeContent/>
      </div>
    </div>
  );
}

export default Home;

