import React from 'react';
import Skills from './Skills.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import '../Home.css';

function AboutMeContent() {
  return (
    <>
      <Card className="card" 
      style={{backgroundColor: 'var(--header-bg-color)', color: 'var(--text-color)',
        margin: '20px'
       }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          ✨ Experience
          </Typography>
          <Typography variant="body2">
              Software Engineer at TikTok on Machine Learning Data Platform team building data processing
              infrastructure and platforms that manage data pipelines and model operational efficiency. 
          </Typography>
          <Typography variant="body2" style={{marginTop: '10px'}}>
              Previously worked at CORE Studio building structural engineering web and desktop applications. 
              Coming from a background in both physical 🏛️ and digital engineering 💻, I provide a unique perspective to 
              solving user challenges.
          </Typography>
        </CardContent>
      </Card>
      <Card className="card" style={{backgroundColor: 'var(--header-bg-color)', 
        color: 'var(--text-color)', margin: '20px'}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          🦋 Career Transition
          </Typography>
          <Typography variant="body2">
            Started my career as a structural engineer P.E. 🏗️ in NYC. While working in a PM role and working
            on complex projects, I began experimenting with Python 🐍 scripts during the steel design of the&nbsp;
            <a className="projectLink" href="https://www.adjaye.com/work/princeton-university-art-museum/" target="_blank" rel="noopener noreferrer">
              Princeton Art Museum</a>&nbsp;to automate time-consuming tasks that required rapid iteration.
          </Typography>
        </CardContent>
      </Card>
      <Card className="card" style={{backgroundColor: 'var(--header-bg-color)', 
        color: 'var(--text-color)', margin: '20px'}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          🎓 Education
          </Typography>
          <Typography variant="body2">
            Before writing software, I studied structural engineering at Cornell (Go Big Red! 🐻) and UIUC where I dabbled with Matlab 
            for structural and finite elment analysis. I began a post-bacc B.S. at Oregon State University in September 2020, 
            and was exposed to the full world of CS knowledge, culminating in an exploration of technical trading during my capstone.
          </Typography>
        </CardContent>
      </Card>
      <Card className="card" style={{backgroundColor: 'var(--header-bg-color)', 
        color: 'var(--text-color)', margin: '20px'}}>
        <Skills />
      </Card>
      <Card className="card" style={{backgroundColor: 'var(--header-bg-color)', 
        color: 'var(--text-color)', margin: '20px'}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          🤟 Personal Tidbits
          </Typography>
          <p>Outside of writing software, I'm an avid 🏒 fan, 🍕 eater, and 🐈 lover.</p>
          <figure style={{marginLeft: '20px'}}>
            <img className = "Ollie" src="/aboutOllie.jpeg" alt="Ollie's Portrait" />
            <figcaption className = "caption">Code Whiskerer</figcaption>
          </figure>
        </CardContent>
      </Card>
  </>
  );
}

export default AboutMeContent;