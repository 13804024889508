import React, { useState } from "react";
import {blogPosts} from './content/BlogContent.jsx';
import './Blog.css';
import parse from 'html-react-parser';  // Install and import this library to parse HTML strings
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Blog() {
  const [openedPostId, setOpenedPostId] = useState(null);

  const handleClick = postId => {
    if (postId === openedPostId) {
      setOpenedPostId(null);
    } else {
      setOpenedPostId(postId);
    }
  };

  return (
    <div className="blog" style={{marginTop: '20px'}}>
      {blogPosts.sort((a, b) => b.id - a.id).map(post => (
        <div key={post.id} className="blog-post" onClick={() => handleClick(post.id)}>
          <Card style={{backgroundColor: 'var(--header-bg-color)', color: 'var(--text-color)', padding: '15px'}}>
          <h2 className = "blog-post-title">{post.title}</h2>
          {post.id === openedPostId
            ? parse(post.content)
            : <>
                {parse(post.content.slice(0, 300))}
                {post.content.length > 300 && '...'}
              </>}
          </Card>
        </div>
      ))}
    </div>
  );
}

export default Blog;
