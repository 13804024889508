import React from 'react';
import { Link } from 'react-router-dom';
import ImagesFromFolder from './ImagesFromFolder';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Skills() {
  return (
    <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        🛠️ Software Skills
        </Typography>
        <table>
            <tr>
                <ImagesFromFolder 
                title = 'Languages'
                fileNames={['python', 'csharp', 'java', 'typescript']} 
                folderPath='./skills/languages'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Frontend'
                fileNames={['react', 'next-js', 'wpf']} 
                folderPath='./skills/frontend'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Backend'
                fileNames={['flask', 'net', 'spring', 'node_js']} 
                folderPath='./skills/backend'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Database'
                fileNames={['postgres', 'mongodb', 'sqlite']} 
                folderPath='./skills/database'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Cloud'
                fileNames={['aws', 'gcp', 'azure']} 
                folderPath='./skills/cloud'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Tools'
                fileNames={['spark', 'docker', 'githubactions', 'auth0']} 
                folderPath='./skills/tools'/>
            </tr>
        </table>
    </CardContent>
  );
}

export default Skills;