export const coreStudioProjects = [
  {
    title: "AutoSTL",
    img: "./autoStl.png",
    github: null,
    gif: "./autoStl.gif",
    live: null,
    technologies: ["ASP.Net Core", "WPF", "MongoDB", "Azure App Service", 
      "Github Actions CI/CD", "Tekla API", ".NET"],
    shortDescription: "Automated precise steel connection 3D design and modeling",
    longDescription: 
    <>
      <p><span>
        AutoSTL is integrated with Trimble Tekla 3D modeling software to automate the detection, 
        design, and modeling of different structural steel connections based on rigorous and precise 
        internal detailing and design standards. Conventional software solutions did not meet the 
        requirements of the construction engineering group. 
      </span></p>
      <p><span>
        Our CORE studio team created a system that was extensible to many types of connections, 
        easy to understand, and accurate. AutoSTL transformed a ~8-minute bug-ridden manual 
        modeling process per connection down to seconds, enabling it to be used rapidly on 
        stadium and airport projects.
      </span></p>
    </>
  },
  {
    title: "Cala",
    img: "./cala.png",
    github: null,
    gif: "./cala.gif",
    live: null,
    technologies: 
    ["WPF", "SQLite", "Procore API", ".NET"],
    shortDescription: "Construction digital file manager and tracking system",
    longDescription: 
    <>
      <p ><span>
        Desktop software utilizing Procore API that automated an aggravating process of sorting, 
        storing, and tracking construction submittals and requests for information (RFI's) during 
        the most chaotic part of the project: construction. For example, some of TT's most 
        significant airport and stadium projects require processing over 50,000 files.
      </span></p>
      <p><span>Our CORE studio team created a system that stored the latest copies of 
        submittals/rfis to network drives, reported items that required responses, and 
        enabled users to focus less on admin. CALA has an estimated annual savings of $250k 
        in skilled labor time.
      </span></p>
    </>
  },
  {
    title: "Graph Analysis",
    img: "./tribArea.png",
    github: null,
    gif: null,
    live: null,
    technologies: 
    [".NET", "Grasshopper", "Data Structures", "Matrices", "Three.js", "React.js"],
    shortDescription: "Graph algorithms for building design and analysis",
    longDescription: 
    <>
      <p><span>
        Tools to enable early structural designs based on preliminary architectural models. 
        These design tools live in Grasshopper and enable users 
        to visualize and track loading from start to finish and perform a simplified sizing analysis.
      </span></p>
      <p><span>
        The development required extensive usage of data structures and focused on 
        graph-based search(BFS and DFS) and geometry-based manipulation (dot products! and angles) 
        to assign loading to different members under non-ambiguous loading conditions. 
        Library was generalized for cross-platform support.
      </span></p>
    </>
  }
]; 

export const osuProjects = [
  {title: "Project Air Ball",
  img: "./air-ball-picks.png",
  github: "https://github.com/birdman093/air-ball",
  gif: null,
  live: "https://air-ball.vercel.app",
  technologies:
  ["Next.js", "DyanmoDB", "Python", "NBA-API", "RapidAPI", "Docker", 
  "AWS - Lambda, EventBridge, CloudWatch, SNS"],
  shortDescription: "NBA Game Line Prediction using Machine Learning",
  longDescription: 
  <>
    <p ><span >Project Air Ball is a machine learning model, seasonal data aggregation, and 
    frontend built using data from the NBA API </span></p>
  </>

  },
  {
    title: "Cloud Trading Optimization",
    img: "./tradingoptimization.png",
    github: null,
    gif: "https://vimeo.com/867341150/8006e0b3dc",
    live: null,
    technologies:
    ["Flask", "MongoDB", "AWS EB", "Backtesting.py", "Pymoo",
    "React.js", "Plotly.js"],
    shortDescription: "Multi-Objective Optimization of algorithm technical trading parameters for highly-volatile stocks",
    longDescription: 
    <>
      <p ><span >Our team of OSU students worked with a project mentor to 
        develop an application that uses multi-objective optimization to 
        find optimized parameters for different trading algorithms. With 
        multi-objective optimization, there is generally more than one solution, 
        and the application generates a pareto distribution of all possible 
        optimized solution sets. The solutions are displayed to the user in a 
        scatter plot, where the user can hover over each plot point to 
        reveal more detailed results. Therefore, the user can choose which
         solution fits their needs best. </span></p>
      <p ><span >After the optimization, the results are stored in a database, 
        allowing the user to retrieve past results if necessary. Optimized results can 
        then be used on sites such as QuantConnect to trade based on the 
        different buy/ sell signals each algorithm yields.</span></p>
    </>
  },
  {
    title: "Clippy-AI",
    img: "./clippy.png",
    github: "https://github.com/rfeathers068/clippy-ai",
    gif: "./clippy.gif",
    live: null,
    technologies: 
    ["OpenAI API", "Flask", "PyRevit API", "WPF"],
    shortDescription: "Hackathon: OpenAI assistant integration for 3D modeling software",
    longDescription: 
    <>
      <p ><span >AEC Tech - New York Hackathon honorable mention project in Fall 2023. 
        Our team brought Clippy back to life as part of an OpenAI integration with 
        Autodesk Revit. By the end of our 26 hours of hacking, we created an application 
        that enabled users to type commands from our UI within Revit and execute code 
        generated by OpenAI (with the help of some context and error handling) to 
        complete those commands.
      </span></p>
    </>
  }
];